import React from "react"

const CloudVideo = ({ cloudVideoUrl, CloudVideoAlt,className }) => {
    return ( 
<>
      <video autoPlay playsInline loop muted className={className} >
        <source src={ cloudVideoUrl } type="video/mp4" alt={ CloudVideoAlt } />
      </video>
</>
     );
}
 
export default CloudVideo;